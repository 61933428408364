import React, { useContext, useEffect, useRef, useState } from "react";
import {
    message,
} from "antd";
import { useNavigate, useParams } from 'react-router-dom';
import axiosInstance from "../../axiosInstance";
import { UserContext } from "../../globalContext";
import { Form } from "react-bootstrap";

function SolveCGame1() {
    const user = useContext(UserContext);
    let navigate = useNavigate();
    const [image, setImage] = useState(null);
    const [wallet1, setWallet1] = useState(null);
    const [wallet2, setWallet2] = useState(null);
    const [selectedOption, setSelectedOption] = useState('');


    const { data } = useParams();
    const decodedData = JSON.parse(decodeURIComponent(data));
    const originalDate1 = new Date(decodedData.gameDate);
    console.log("decodeData", decodedData.game.player1)
    useEffect(() => {

        let mounted = true;
        if (mounted) {

        }
        pageLoad();
        return () => (mounted = false);
    }, []);
    const pageLoad = () => {
        getWallet1();
        getWallet2();
    }
    const getWallet1 = () => {
        axiosInstance.get(`/wallet/${decodedData.game.player1}`).then((res) => {
            setWallet1(res.data.data);
            console.log("wallet1", res.data.data)
        });
    }
    const getWallet2 = () => {
        axiosInstance.get(`/wallet/${decodedData.game.player2}`).then((res) => {
            setWallet2(res.data.data);
            console.log("wallet2", res.data.data)
        });
    }
    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    };
    const Confirm = () => {
        if (selectedOption === "player1") {
            const data = {
                winningAmount: decodedData.winningAmount + wallet1.winningAmount,
                winningFreezAmount: 0
            }
            axiosInstance.put(`wallet/${decodedData.game.player1}`, data).then((res) => {
                if (res.data && res.data.responseCode === 1) {
                    const data1 = {
                        status: "solved"
                    }
                    axiosInstance.put(`game1F/${decodedData._id}`, data1).then((res) => {
                        if (res.data && res.data.responseCode === 1) {
                            const data2 = {
                                player1Status: "winner",
                                player2Status: "looser",
                                winner: "player1",
                                froudStatus: false,

                            }
                            axiosInstance.put(`game/${decodedData.game._id}`, data2).then((res) => {

                                if (res.data && res.data.responseCode === 1) {
                                    message.success("Success");
                                    navigate('/fCGame1');

                                } else message.error("Something wrong. Please try again...!");
                            });
                        } else message.error("Something wrong. Please try again...!");
                    });
                } else message.error("Something wrong. Please try again...!");
            });
        }
        else {
            if (selectedOption === "player2") {
                const data = {
                    winningAmount: decodedData.winningAmount + wallet2.winningAmount,
                    winningFreezAmount: 0
                }
                axiosInstance.put(`wallet/${decodedData.game.player2}`, data).then((res) => {
                    if (res.data && res.data.responseCode === 1) {
                        const data1 = {
                            status: "solved"
                        }
                        axiosInstance.put(`game1F/${decodedData._id}`, data1).then((res) => {
                            if (res.data && res.data.responseCode === 1) {
                                const data2 = {
                                   
                                    player1Status: "looser",
                                    player2Status: "winner",
                                    winner: "player2",
                                    froudStatus: false,

                                }
                                axiosInstance.put(`game/${decodedData.game._id}`, data2).then((res) => {

                                    if (res.data && res.data.responseCode === 1) {
                                        message.success("Success");
                                        navigate('/fCGame1');

                                    } else message.error("Something wrong. Please try again...!");
                                });
                            } else message.error("Something wrong. Please try again...!");
                        });
                    } else message.error("Something wrong. Please try again...!");
                });
            }
            else {
                if (selectedOption === "cancel") {
                    const data = {
                        depositeAmount: decodedData.gameAmount + wallet2.depositeAmount
                            - (decodedData.gameAmount * .1),
                        winningFreezAmount: 0
                    }
                    axiosInstance.put(`wallet/${decodedData.game.player2}`, data).then((res) => {
                        if (res.data && res.data.responseCode === 1) {
                            const data1 = {
                                status: "solved"
                            }
                            axiosInstance.put(`game1F/${decodedData._id}`, data1).then((res) => {
                                if (res.data && res.data.responseCode === 1) {
                                    const data2 = {
                                      
                                        player1Status: "tie",
                                        player2Status: "tie",
                                        winner: "tie",
                                        froudStatus: false,

                                    }
                                    axiosInstance.put(`game/${decodedData.game._id}`, data2).then((res) => {

                                        if (res.data && res.data.responseCode === 1) {
                                            const data3 = {
                                                depositeAmount: decodedData.gameAmount + wallet1.depositeAmount - (decodedData.gameAmount * .1),
                                                winningFreezAmount: 0
                                            }
                                            console.log("data3",data3)
                                            axiosInstance.put(`wallet/${decodedData.game.player1}`, data3).then((res) => {
                                                if (res.data && res.data.responseCode === 1) {
                                                    message.success("Success");
                                                    navigate('/fCGame1');
                                                } else message.error("Something wrong. Please try again...!");
                                            });
                                        } else message.error("Something wrong. Please try again...!");
                                    });
                                } else message.error("Something wrong. Please try again...!");
                            });
                        } else message.error("Something wrong. Please try again...!");
                    });
                }
                else {
                    if (selectedOption === "cancelFree") {
                        const data = {
                            depositeAmount: decodedData.gameAmount + wallet2.depositeAmount,
                            winningFreezAmount: 0
                        }
                        axiosInstance.put(`wallet/${decodedData.game.player2}`, data).then((res) => {
                            if (res.data && res.data.responseCode === 1) {
                                const data1 = {
                                    status: "solved"
                                }
                                axiosInstance.put(`game1F/${decodedData._id}`, data1).then((res) => {
                                    if (res.data && res.data.responseCode === 1) {
                                        const data2 = {
                                            player1Status: "tie",
                                            player2Status: "tie",
                                            winner: "tie",
                                            froudStatus: false,

                                        }
                                        axiosInstance.put(`game/${decodedData.game._id}`, data2).then((res) => {
    
                                            if (res.data && res.data.responseCode === 1) {
                                                const data3 = {
                                                    depositeAmount: decodedData.gameAmount + wallet1.depositeAmount,
                                                    winningFreezAmount: 0
                                                }
                                                console.log("data3",data3)
                                                axiosInstance.put(`wallet/${decodedData.game.player1}`, data3).then((res) => {
                                                    if (res.data && res.data.responseCode === 1) {
                                                        message.success("Success");
                                                        navigate('/fCGame1');
                                                    } else message.error("Something wrong. Please try again...!");
                                                });
                                            } else message.error("Something wrong. Please try again...!");
                                        });
                                    } else message.error("Something wrong. Please try again...!");
                                });
                            } else message.error("Something wrong. Please try again...!");
                        });
                    }
                    else{
                        if (selectedOption === "refundPlayer1") {
                            const data = {
                                depositeAmount: decodedData.gameAmount + wallet1.depositeAmount,
                                winningFreezAmount: 0
                            }
                            axiosInstance.put(`wallet/${decodedData.game.player1}`, data).then((res) => {
                                if (res.data && res.data.responseCode === 1) {
                                    const data1 = {
                                        status: "solved"
                                    }
                                    axiosInstance.put(`game1F/${decodedData._id}`, data1).then((res) => {
                                        if (res.data && res.data.responseCode === 1) {
                                            const data2 = {
                                                player1Status: "looser",
                                                player2Status: "looser",
                                                winner: "tie",
                                                froudStatus: false,
    
                                            }
                                            axiosInstance.put(`game/${decodedData.game._id}`, data2).then((res) => {
        
                                                if (res.data && res.data.responseCode === 1) {
                                                    message.success("Success");
                                                    navigate('/fCGame1');
                                                   
                                                } else message.error("Something wrong. Please try again...!");
                                            });
                                        } else message.error("Something wrong. Please try again...!");
                                    });
                                } else message.error("Something wrong. Please try again...!");
                            });
                        }
                        else{
                            if (selectedOption === "refundPlayer2") {
                                const data = {
                                    depositeAmount: decodedData.gameAmount + wallet2.depositeAmount,
                                    winningFreezAmount: 0
                                }
                                axiosInstance.put(`wallet/${decodedData.game.player2}`, data).then((res) => {
                                    if (res.data && res.data.responseCode === 1) {
                                        const data1 = {
                                            status: "solved"
                                        }
                                        axiosInstance.put(`game1F/${decodedData._id}`, data1).then((res) => {
                                            if (res.data && res.data.responseCode === 1) {
                                                const data2 = {
                                                    player1Status: "looser",
                                                    player2Status: "looser",
                                                    winner: "tie",
                                                    froudStatus: false,
        
                                                }
                                                axiosInstance.put(`game/${decodedData.game._id}`, data2).then((res) => {
            
                                                    if (res.data && res.data.responseCode === 1) {
                                                        message.success("Success");
                                                        navigate('/fCGame1');
                                                       
                                                    } else message.error("Something wrong. Please try again...!");
                                                });
                                            } else message.error("Something wrong. Please try again...!");
                                        });
                                    } else message.error("Something wrong. Please try again...!");
                                });
                            }
                        }
                    }
                }
            }
        }

    };
    return (
       <div>
         <div style={{justifyContent:"center" , alignItems:"center", display:"flex"}}>
            <label htmlFor="selectOptions"><h4>Select an Winner:</h4></label>
            <select id="selectOptions" style={{height:"35px"}} value={selectedOption} onChange={handleSelectChange}>
                <option value="">Select...</option>
                <option value="player1">player 1</option>
                <option value="player2">player 2</option>
                <option value="cancel">Cancel Game</option>
                <option value="cancelFree">Cancel Without Commission Game</option>
                <option value="refundPlayer1">Refund Player 1</option>
                <option value="refundPlayer2">Refund Player 2</option>
            </select>
           
          
        </div>
        <div style={{display:"flex" , justifyContent:"center"}}>
        <button className="btn-success "  style={{width:"100px",height:"35px" ,marginLeft:"250px"}} onClick={() => Confirm()}>Confirm</button>

        </div>
       </div>
    );
}

export default SolveCGame1;
