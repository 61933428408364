import React, {useContext, useEffect, useState,useRef} from "react";
import { Table, Modal, message, Row, Space, Input, Button } from "antd";
import { EditOutlined, DeleteOutlined, EyeOutlined ,SearchOutlined} from "@ant-design/icons";
import { PageTitle } from "../../PageTitle";
import axiosInstance from "../../axiosInstance";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from 'moment';
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../globalContext";
import Highlighter from "react-highlight-words";


const CancelGameList = () => {
  const user = useContext(UserContext);
  let navigate = useNavigate(); 
  const [list, setList] = useState([]);
  const [readMemberObj, setReadMemberObj] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [companyId, setCompanyId] = useState('');
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, pickupService) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(pickupService);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (pickupService) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${pickupService}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys, confirm, pickupService)
          }
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, pickupService)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(pickupService);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[pickupService]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === pickupService ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });


  useEffect(() => {
    let mounted = true;
    if (mounted) pageLoad();
    return () => (mounted = false);
  }, []);
  
  const pageLoad = () => {
    getGame();
  }
  const columns = [
    {
      title: "Table Creator",
      ...getColumnSearchProps("name"),
      render: (row) => {
        return row.player1 ? (
          <div>

          { row&& <b>{row.player1.name}</b>}<br/>
          <label className="pickup-list-label">{row.player1.mobile}</label>
            </div>
        ) : (
          <div> </div>
        );
      },
    },
    {
        title: "Game Amount",
        dataIndex: "gameAmount",
        key: "gameAmount",
        ...getColumnSearchProps("gameAmount"),
      },
      {
        title: "Game Status",
        dataIndex: "status",
        key: "status",
      },
    {
      title: "Date",
     // dataIndex: "gameDate",
      ...getColumnSearchProps("gameDate"),
      key: "gameDate",
      render: (row) => {
        return (
            <>
                {row && <div style={{ width: "100px" }}><b style={{ color: "green" }}>{moment(row.gameDate).format("DD-MM-yyyy  hh.mm.ss")}</b></div>}
            </>
        );
    },
      // render:(gameDate)=>{
      //   return(
      //     <div>{moment(gameDate).format('DD-MMM-yyyy')}</div>
      //   )
      // }
    },
  ];

  const getGame = () => {
    axiosInstance.post("/game/cancel").then((response) => {
      const sortedData = [...response.data.data].sort(
        (a, b) => new Date(b.gameDate) - new Date(a.gameDate)
      );
      setList(sortedData);
    });
  
  
  };

  const exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Member List";
    const headers =  [["Name", "Email","Mobile","Address"]]

    const data = list.map(elt=> [elt.name, elt.email,elt.mobile,elt.address]);

    let content = {
      startY: 50,
      head: headers,
      body: data
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("MemberList.pdf")
  }
  
  return (
 <div>
   <div className=" text-center main-heading">
          {/* <PageTitle title=""></PageTitle> */}
          <h1>Cancled Game List</h1>
        </div>
     <div className="member-list-screen container">
      <div>
 
      </div>
      <div >
      {(user.role==='Admin')&&
      <div className="row mb-3 end">
          
            <div className="col-md-4 text">
              <label className="my">Company name</label>
          <Input placeholder="Search By Company Name"></Input>
          </div>
          <div className="col-md-4 text">
          <Button className="btnmy">Search</Button>
          </div>
         
        </div>}
      </div>
      <div>
        <Table rowKey="id" columns={columns} dataSource={list} />
      </div>
      {/* <div>
        <button onClick={() => exportPDF()}>Generate Report</button>
      </div> */}
    
  
    </div>
 </div>
  );
};

export default CancelGameList;
