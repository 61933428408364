import React, { useContext, useEffect, useState, useRef } from "react";
import { Table, Modal, message, Row, Space, Input, Button } from "antd";
import { EditOutlined, DeleteOutlined, EyeOutlined, SearchOutlined } from "@ant-design/icons";
import { PageTitle } from "../../PageTitle";
import axiosInstance from "../../axiosInstance";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from 'moment';
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../globalContext";
import Highlighter from "react-highlight-words";


const ReportedCGameList2 = () => {
    const user = useContext(UserContext);
    let navigate = useNavigate();
    const [list, setList] = useState([]);
    const [readMemberObj, setReadMemberObj] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [imageUrl, setImageUrl] = useState('');
    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, pickupService) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(pickupService);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText("");
    };
    const getColumnSearchProps = (pickupService) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${pickupService}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() =>
                        handleSearch(selectedKeys, confirm, pickupService)
                    }
                    style={{
                        marginBottom: 8,
                        display: "block",
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, pickupService)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(pickupService);
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? "#1677ff" : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[pickupService]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === pickupService ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: "#ffc069",
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ""}
                />
            ) : (
                text
            ),
    });


    useEffect(() => {
        let mounted = true;
        if (mounted) pageLoad();
        return () => (mounted = false);
    }, []);

    const pageLoad = () => {
        getGame();
    }
    const columns = [
        {
            title: "Player 1",
            ...getColumnSearchProps("name"),
            render: (row) => {
                return row.player1 ? (
                    <div>
                        {row && <b>{row.player1.name}</b>}<br />
                        <label className="pickup-list-label">{row.player1.mobile}</label>
                    </div>
                ) : (
                    <div> </div>
                );
            },
        },
        {
            title: "Player 2",
            ...getColumnSearchProps("name"),
            render: (row) => {
                return row.player2 ? (
                    <div>
                        {row && <b>{row.player2.name}</b>}<br />
                        <label className="pickup-list-label">{row.player2.mobile}</label>

                    </div>
                ) : (
                    <div> </div>
                );
            },
        },
        {
            title: "Game Amount",
            dataIndex: "gameAmount",
            key: "gameAmount",
            ...getColumnSearchProps("gameAmount"),
        },
        {
            title: "game joined Date",
            // dataIndex: "winningAmount",
            // key: "winningAmount",
            render: (row) => {
            return(
                <>
                {row.game && <div style={{width:"100px"}}><b style={{color:"green"}}>{moment(row.game.gameJoinedDate).format("DD-MM-yyyy  hh.mm.ss")}</b></div>}
                </>
              );
            },
            
        },
        {
            title: "game cancel Date",
            // dataIndex: "winningAmount",
            // key: "winningAmount",
            render: (row) => {
            return(
                <>
                {row && <div style={{width:"100px"}}><b style={{color:"red"}}>{moment(row.gameFDate).format("DD-MM-yyyy  hh.mm.ss")}</b></div>}
                </>
              );
            },
            
        },
        {
            title: "Roome Code",
            dataIndex: "roomCode",
            key: "roomCode",
            ...getColumnSearchProps("roomCode"),
        },
        {
            title: "Froud Status",
            dataIndex: "status",
            key: "status",
        },
        {
            title: "Game Winner",
            render: (row) => {
                return row.game ? (
                    <div>
                        {row && <b>{row.game.winner}</b>}<br />

                    </div>
                ) : (
                    <div> </div>
                );
            },
        },
        {
            title: "Reporter",
            render: (row) => {
                return row.reporter ? (
                    <div>
                        {row && <b>{row.reporter.name}</b>}<br />
                        <label className="pickup-list-label">{row.reporter.mobile}</label>
                    </div>
                ) : (
                    <div> </div>
                );
            },
        },
        
        {
            title: "Action",
            key: "id",
            render: (row) => {
                return (
                    <>
                        {/* <Button style={{ color: "green", marginLeft: 12 }}
                            onClick={() => {
                                viewImage(row);
                            }} >
                            View Image
                        </Button> */}
                        <Button
                            style={{ color: "red", marginLeft: 12, marginTop:5 }}
                            onClick={() => {
                                updateStatus(row);
                            }}>
                            Solve Game
                        </Button>

                        {/* <DeleteOutlined
                style={{ color: "red", marginLeft: 12 }}
                onClick={() => {
                  removePayment(row);
                }}
              /> */}
                    </>
                );
            },
        },
    ];
    
  

    const updateStatus = (obj) => {
        console.log("obj", obj)
        navigate(`/solveCGame2/${encodeURIComponent(JSON.stringify(obj))}`, { replace: true });
    }
    const getGame = () => {
        axiosInstance.post("/game2F/cancel1").then((response) => {
            const sortedData = [...response.data.data].sort(
                (a, b) => new Date(b.gameDate) - new Date(a.gameDate)
            );
            console.log("sorted", response.data.data)
            setList(sortedData);
        });


    };


    return (
        <div>
            <div className=" text-center main-heading">
                {/* <PageTitle title=""></PageTitle> */}
                <h1>
                    fraud Game2 List</h1>
            </div>
            <div className="member-list-screen container">
                <div>

                </div>
                <div >
                </div>
                <div>
                    <Table rowKey="id" columns={columns} dataSource={list} />
                </div>
                {/* <div>
        <button onClick={() => exportPDF()}>Generate Report</button>
      </div>
     */}
                <Modal
                    title="Winning Image"
                    open={isModalOpen}
                    onOk={() => setIsModalOpen(false)}
                    onCancel={() => setIsModalOpen(false)}
                >
                    <div>
                        {imageUrl && <img src={imageUrl} alt="Uploaded" />}
                    </div>
                </Modal>
            </div>
        </div>
    );
};

export default ReportedCGameList2;
